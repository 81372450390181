export const LicensePages = [
    {
        id: 0,
        cards: [0,1,2],
        name: ''
    },
    {
        id: 1,
        cards: [3,4,5,6,7,8,9],
        name: 'Commercial Business'
    },
    {
        id: 2,
        cards: [10,11,12,13],
        name: 'Home Business'
    },
    {
        id: 3,
        cards: [14,15],
        name: 'Short-Term Rental'
    }
];