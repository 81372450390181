// These are the 'cards' shown in the Permit Assistant
import { Text } from "@nextui-org/react";

export const PermitCards = [
    {questionText : 'Non-Residential',
    categoryText : 'Permit Category',
    id : 0, 
    nextPage : 1,
    endpagelink : '',
    addinfo : 'Permits for non-residential properties; places you shop, work, and practice religion',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Residential', 
    categoryText : 'Permit Category',
    id : 1, 
    nextPage : 2,
    endpagelink : '',
    addinfo : 'Permits for residential properties',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Demolition', 
    categoryText : 'Permit Category',
    id : 2, 
    nextPage : 3,
    endpagelink : '',
    addinfo : 'Permits for destroying a building or any piece of it',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Electrical', 
    categoryText : 'Permit Category',
    id : 3, 
    nextPage : 5,
    endpagelink : '',
    addinfo : 'Permits for electrical projects',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Mechanical', 
    categoryText : 'Permit Category',
    id : 4, 
    nextPage : 7,
    endpagelink : '',
    addinfo : 'Permits for mechanical projects; includes HVAC, boilers, etc.',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Plumbing', 
    categoryText : 'Permit Category',
    id : 5, 
    nextPage : 9,
    endpagelink : '',
    addinfo : 'Permits for plumbing projects',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Signs', 
    categoryText : 'Permit Category',
    id : 6, 
    nextPage : 11,
    endpagelink : '',
    addinfo : 'Permits for constructing/repairing/replacing permanent signs and for temporary signage ',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Accessory Structure',
    categoryText : 'Permit', 
    id : 7, 
    nextPage : 0,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/66/0/0',
    addinfo : 'Required for building an accessory structure',
    endpage : true,
    requirements : [1,8,9,14,16,30,31,33],
    },
    {questionText : 'Addition', 
    categoryText : 'Permit',
    id : 8, 
    nextPage : 0,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/46/0/0',
    addinfo : 'Required when adding on to an existing commerical structure',
    endpage : true,
    requirements : [32,7,8,9,10,11,12,14,23,30,33],
    },
    {questionText : 'Building Shell', 
    categoryText : 'Permit',
    id : 9, 
    nextPage : 1,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/47/0/0',
    addinfo : 'Required when building only the outer shell of a building',
    endpage : true,
    requirements : [32,7,9,10,11,12,14,23],
    },
    {questionText : 'Communication Tower Upgrade', 
    categoryText : 'Permit',
    id : 10, 
    nextPage : 4,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/65/0/0',
    addinfo : 'Required when making changes or updates to existing communication tower',
    endpage : true,
    requirements : [32,10,11,12,14,30,33,34],
    },
    {questionText : 'Industrial', 
    categoryText : 'Permit',
    id : 11, 
    nextPage : 5,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/48/0/0',
    addinfo : 'Required when building an industrial building',
    endpage : true,
    requirements : [32,7,9,10,11,12,14,23,30,31,33],
    },
    {questionText : 'New Communication Tower', 
    categoryText : 'Permit',
    id : 12, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/64/0/0',
    addinfo : 'Required for erecting a new communication tower',
    endpage : true,
    requirements : [32,8,9,10,11,12,14,30,33,34],
    },
    {questionText : 'Office/Institutional',
    categoryText : 'Permit',
    id : 13, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/61/0/0',
    addinfo : 'Required for building an Office or Institutional space',
    endpage : true,
    requirements : [32,7,9,10,11,12,14,16,23,30,31,33],
    },
    {questionText : 'Places of Worship',
    categoryText : 'Permit',
    id : 14, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/62/0/0',
    addinfo : 'Required for building a Place of Worship',
    endpage : true,
    requirements : [32,8,9,10,11,12,14,16,23,30,31,33],
    },
    {questionText : 'Renovation/Alteration',
    categoryText : 'Permit',
    id : 15, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/63/0/0',
    addinfo : 'Any renovation or alteration to an existing commercial building.',
    endpage : true,
    requirements : [10,11,12,14,16,23,27,28,30,31,33],
    },
    {questionText : 'Retail',
    categoryText : 'Permit',
    id : 16, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/49/0/0',
    addinfo : 'Permit for retail building establishment',
    endpage : true,
    requirements : [32,7,9,10,11,12,14,16,23,29,30,31,33],
    },
    {questionText : 'Accessory Dwelling',
    categoryText : 'Permit',
    id : 17, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/38/0/0',
    addinfo : 'Permit for building another inhabitable building on a residential property that already has an existing main dwelling.',
    endpage : true,
    requirements : [0,3,4,5,7,8,9,10,11,12,14,23],
    },
    {questionText : 'Accessory Structure',
    categoryText : 'Permit',
    id : 18, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/33/0/0',
    addinfo : 'Permit for uninhabitable structures detached from the main dwelling. Includes detached garages, pools, sheds, fences, gazebos,and retaining walls.',
    endpage : true,
    requirements : [1,3,8,9,10,11,12,13,14],
    },
    {questionText : 'Addition',
    categoryText : 'Permit',
    id : 19, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/34/0/0',
    addinfo : 'Required for building a structure connected to the main structure such as a deck or room.',
    endpage : true,
    requirements : [1,3,4,8,9,10,11,12,13,14,27,28],
    },
    {questionText : 'House Moving, Into the County',
    categoryText : 'Permit',
    id : 20, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/44/0/0',
    addinfo : 'Required when moving a Single Family House into of the County',
    endpage : true,
    requirements : [1,2,3,4,5,6,7,8,9,10,11,12,14,21,22,23,26],
    },
    {questionText : 'House Moving, Out/Thru the County',
    categoryText : 'Permit',
    id : 21, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/44/0/0',
    addinfo : 'Required when moving a Single Family House out or through the County',
    endpage : true,
    requirements : [10,11,14,19,21,23,26],
    },
    {questionText : 'Mobile Home',
    categoryText : 'Permit',
    id : 22, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/43/0/0',
    addinfo : 'Permit for setting up a mobile home.',
    endpage : true,
    requirements : [10,11,14,17,18],
    },
    {questionText : 'Model Home',
    categoryText : 'Permit',
    id : 23, 
    nextPage : 3,
    endpagelink : '',
    addinfo : 'Could not find on energov',
    endpage : true,
    requirements : [10,11,14,17,18],
    },
    {questionText : 'New Home',
    categoryText : 'Permit',
    id : 24, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/42/0/0',
    addinfo : 'Permit for building a residential dwelling on a property; includes single family, duplexes, townhomes, and condos',
    endpage : true,
    requirements : [0,2,3,4,5,6,7,8,9,10,11,12,13,14,23],
    },
    {questionText : 'Renovation/Alteration',
    categoryText : 'Permit',
    id : 25, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/45/0/0',
    addinfo : <Text>
        Permit for renovating an existing residential structure. 
        <br />Note: If existing damage of the structure is found to be greater than 49%, you must apply for a new building permit, not a renovation/alteration permit.
        </Text>,
    endpage : true,
    requirements : [10,11,12,13,14,23,27,28],
    },
    {questionText : 'Demolition, Not to Rebuild',
    categoryText : 'Permit',
    id : 26, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/95/0/0',
    addinfo : 'Demolition Not To Rebuild',
    endpage : true,
    requirements : [11,14,19,20,21],
    },
    {questionText : 'Demolition, to Rebuild',
    categoryText : 'Permit',
    id : 27, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/96/0/0',
    addinfo : 'Demolition To Rebuild',
    endpage : true,
    requirements : [11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 28, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Interior Demolition',
    categoryText : 'Permit',
    id : 29, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/98/0/0',
    addinfo : 'Interior Demolition',
    endpage : true,
    requirements : [11,14,35],
    },
    {questionText : 'Commercial, New',
    categoryText : 'Permit',
    id : 30, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/39/0/0',
    addinfo : 'Electrical permit for new commercial buildings',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Commercial, Stand-Alone',
    categoryText : 'Permit',
    id : 31, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/69/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Low-Voltage',
    categoryText : 'Permit',
    id : 32, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/71/0/0',
    addinfo : 'Required for Low Voltage Permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Reconnect/Disconnect Only',
    categoryText : 'Permit',
    id : 33, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/70/0/0',
    addinfo : '',
    endpage : true,
    requirements : [14],
    },
    {questionText : '',
    categoryText : '',
    id : 34, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Residential, Stand-Alone',
    categoryText : 'Permit',
    id : 35, 
    nextPage : 3,
    endpagetext : '',
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/68/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 36, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Commercial, New',
    categoryText : 'Permit',
    id : 37, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/99/0/0',
    addinfo : 'Mechanical permit for new commercial buildings',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Commercial, Stand-Alone',
    categoryText : 'Permit',
    id : 38, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/100/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Duct Work Only',
    categoryText : 'Permit',
    id : 39, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/79/0/0',
    addinfo : '',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Gas Reconnect',
    categoryText : 'Permit',
    id : 40, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/78/0/0',
    addinfo : 'Permit for reconnecting natural gas service to a building',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 41, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Residential, Stand-Alone',
    categoryText : 'Permit',
    id : 42, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/104/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 43, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Commercial, New',
    categoryText : 'Permit',
    id : 44, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/73/0/0',
    addinfo : 'Plumbing permit for new commercial buildings',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Commercial, Stand-Alone',
    categoryText : 'Permit',
    id : 45, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/74/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : 'Gas Reconnect',
    categoryText : 'Permit',
    id : 46, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/94/0/0',
    addinfo : '',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 47, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'Residential, Stand-Alone',
    categoryText : 'Permit',
    id : 48, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/76/0/0',
    addinfo : 'Can be linked to a building permit',
    endpage : true,
    requirements : [10,11,14],
    },
    {questionText : '',
    categoryText : '',
    id : 49, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : '',
    categoryText : '',
    id : 50, 
    nextPage : 0,
    endpagelink : '',
    addinfo : '',
    endpage : true,
    requirements : [],
    },
    {questionText : 'New Signage',
    categoryText : 'Permit',
    id : 51, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/105/0/0',
    addinfo : <Text>Permit for new signs <br /> Types of signs include: ground, wall, entrance, billboard, internal, menu board, and window signs</Text>,
    endpage : true,
    requirements : [40,42,11,14,16,36,37,38,39],
    },
    {questionText : 'Reface an Existing Sign',
    categoryText : 'Permit',
    id : 52, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/106/0/0',
    addinfo : 'Permit for new graphic design(s) on the same sign structure',
    endpage : true,
    requirements : [40,42,11,14,16,36,37,38,39],
    },
    {questionText : 'Repair an Existing Sign',
    categoryText : 'Permit',
    id : 53, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/82/0/0',
    addinfo : 'Permit for repairing the sign structure',
    endpage : true,
    requirements : [40,42,11,14,16,36,37,38,39],
    },
    {questionText : 'Temporary Signage',
    categoryText : 'Permit',
    id : 54, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/107/0/0',
    addinfo : 'Permit for sign that are only in place temporarily',
    endpage : true,
    requirements : [40,42,11,14,16,36,37,38,39],
    },
    {questionText : 'Special Event',
    categoryText : 'Permit',
    id : 55, 
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/80/0/0',
    addinfo : 'Permits for things like concerts, 5Ks, 10Ks, etc.',
    endpage : true,
    requirements : [40,19,22,41],
    },
    {questionText : 'Demolition',
    categoryText : 'Permit Category',
    id : 56, 
    nextPage : 4,
    endpagelink : '',
    addinfo : 'Permits for destroying a building or any piece of it',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Electrical',
    categoryText : 'Permit Category',
    id : 57, 
    nextPage : 6,
    endpagelink : '',
    addinfo : 'Permits for electrical projects',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Mechanical',
    categoryText : 'Permit Category',
    id : 58, 
    nextPage : 8,
    endpagelink : '',
    addinfo : 'Permits for mechanical projects; includes HVAC, boilers, etc.',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Plumbing',
    categoryText : 'Permit Category',
    id : 59, 
    nextPage : 10,
    endpagelink : '',
    addinfo : 'Permits for plumbing projects',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Moving a House',
    categoryText : 'Permit',
    id : 60, 
    nextPage : 12,
    endpagelink : '',
    addinfo : 'Permits for transporting homes in the county',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Building', // Commercial
    categoryText : 'Permit Category',
    id : 61, 
    nextPage : 13,
    endpagelink : '',
    addinfo : 'Permits for constructing/renovating buildings and places of worship',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Building', // Residential
    categoryText : 'Permit Category',
    id : 62, 
    nextPage : 14,
    endpagelink : '',
    addinfo : 'Permits for constructing/renovating homes and other residential buildings',
    endpage : false,
    requirements : [],
    },
    {questionText : 'Apartment Building',
    categoryText : 'Permit',
    id : 63, 
    nextPage : 14,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/permit/apply/163/0/0',
    addinfo : 'Permit for constructing new apartment buildings.',
    endpage : true,
    requirements : [0,7,9,10,11,12,14,23],
    },
    {questionText : 'Fire and Building Commercial Plans Review',
    categoryText : 'Plans Review',
    id : 64,
    nextPage : 3,
    endpagelink : 'https://selfservice.claytoncountyga.gov/EnerGovProd/SelfService#/applicationAssistant?sectionName=All&moduleId=3&categoryName=Building%20Plans%20Review&showTemplates=false',
    addinfo : 'This is NOT a permit, it is for fire and building review only',
    endpage : true,
    requirements : [30,33,29,31,9,0],
    }
    ];


