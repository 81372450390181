export const PermitPages = [
    {id : 0,
    cards : [0,1,55],
    name : ''},
    {id : 1,
    cards : [61,2,3,4,5,6,64],
    name : 'Non-Residential Permits'},
    {id : 2,
    cards : [62,56,57,58,59,60],
    name : 'Residential Permits'},
    {id : 3,
    cards : [26,27,29],
    name : 'Non-Residential Demolition Permits'},
    {id : 4,
    cards : [26,27,29],
    name : 'Residential Demolition Permits'},
    {id : 5,
    cards : [30,31,32,33],
    name : 'Non-Residential Electrical Permits'},
    {id : 6,
    cards : [35,32,33],
    name : 'Residential Electrical Permits'},
    {id : 7,
    cards : [37,38,39,40],
    name : 'Non-Residential Mechanical Permits'},
    {id : 8,
    cards : [42,39,40],
    name : 'Residential Mechanical Permits'},
    {id : 9,
    cards : [44,45,46],
    name : 'Non-Residential Plumbing Permits'},
    {id : 10,
    cards : [48,46],
    name : 'Residential Plumbing Permits'},
    {id : 11,
    cards : [51,52,53,54],
    name : 'Sign Permits'},
    {id : 12,
    cards : [20,21],
    name : 'Permits for Transporting Homes'},
    {id : 13,
    cards : [16,13,11,9,7,8,14,15,10,12],
    name : 'Non-Residential Building Permits'},
    {id : 14,
    cards : [25,24,22,63,19,18,17],
    name : 'Residential Building Permits'}
];