export const ClaytonLogo = () => (
  <svg width="194" height="50" version="1.1" viewBox="0 0 1138.9 293.44" xmlns="http://www.w3.org/2000/svg">
  <g strokeWidth=".32">
  <path d="m65.438 67.301c-11.612-2.5942-20.518-4.4476-26.24-5.4607-7.8277-1.386-10.96-2.1011-11.096-2.5337-0.05558-0.176 1.2762-1.976 2.9594-4 16.82-20.224 35.454-34.455 57.4-43.834 8.7969-3.7597 20.933-7.446 30.513-9.2682 1.9009-0.36157 3.5209-0.6574 3.6-0.6574 0.56717 0-0.53387 1.1201-5.4527 5.5469-16.801 15.121-32.775 36.057-42.874 56.194-0.95691 1.908-2.1259 3.7728-2.5978 4.144-1.0661 0.83862-1.9566 0.81988-6.2117-0.13074z" fill="#2774ae"/>
  <path d="m221.23 68.416c-0.29284-0.17091-1.6779-2.5065-3.078-5.1903-11.331-21.72-25.282-39.764-43.578-56.361-3.6695-3.3288-4.4899-4.2189-3.9596-4.2958 0.36793-0.0534 2.5228 0.28339 4.7886 0.74841 25.586 5.2511 47.502 15.987 67 32.821 6.8392 5.9048 17.236 16.864 21.869 23.051l0.8709 1.1631-2.1698 0.51343c-1.1934 0.28239-3.9698 0.81108-6.1698 1.1749-5.4663 0.90391-13.562 2.5412-23.759 4.8052-8.4332 1.8724-10.765 2.1822-11.814 1.57z" fill="#ee2737"/>
  <path d="m208.48 69.52c-3.6544-2.8005-9.1696-5.7354-19.33-10.287-13.107-5.8712-22.187-8.6904-33.085-10.272-2.3402-0.33974-4.3211-0.68391-4.402-0.76483-0.0809-0.0809-0.1102-10.916-0.0651-24.079l0.0821-23.932 4.5254 3.4804c7.2386 5.567 12.307 10.035 19.173 16.901 13.71 13.711 24.21 28.022 33.167 45.208 2.0819 3.9943 2.4415 4.8872 1.9615 4.8698-0.32329-0.0118-1.2358-0.518-2.0278-1.1249z" fill="#702f8a"/>
  <path d="m83.796 66.616c9.2307-18.134 23.33-36.789 38.189-50.529 6.1693-5.7045 18.025-15.5 18.76-15.5 0.11643 0 0.21169 10.634 0.21169 23.632v23.632l-3.92 0.22113c-19.526 1.1015-38.024 8.2472-52.467 20.268l-2.8655 2.385z" fill="#ffb549"/>
  <path d="m117.37 73.915c-16.496-1.12-21.431-1.5491-21.188-1.8421 0.43137-0.51976 6.4867-4.1634 10.212-6.145 4.4438-2.3636 10.29-4.9683 13.733-6.1184 3.6077-1.2052 11.064-2.734 15.707-3.2207 1.848-0.19371 3.684-0.42023 4.08-0.50338l0.72-0.15117v19.532l-0.88-0.0456c-0.484-0.0251-10.557-0.70267-22.385-1.5057z" fill="#dde5ed"/>
  <path d="m151.52 66.023v-9.7168l1.2 0.16574c9.206 1.2715 15.534 3.0731 24.145 6.8741 7.8621 3.4703 17.576 8.6654 17.097 9.1439-0.0811 0.0811-8.9079 0.77581-19.615 1.5437s-20.224 1.4659-21.148 1.5511l-1.68 0.15492z" fill="#dde5ed"/>
  <path d="m226.83 82.319c-0.87338-2.4437-1.1996-3.7467-0.96-3.8348 0.4258-0.15657 22.56-4.9066 33.643-7.2199 4.224-0.88164 8.5261-1.627 9.5603-1.6564 1.8486-0.0525 1.9018-0.0292 3.1612 1.3824 1.1401 1.278 6.8134 12.208 7.4308 14.316l0.21089 0.72-51.723-6e-3 -1.3229-3.7014z" fill="#cb333b"/>
  <path d="m224 135.26c-16.633-11.034-39.279-19.282-60-21.855-4.0293-0.50023-10.745-1.1329-12.08-1.138l-0.72-3e-3v-28.018l12.88-0.5649c21.592-0.94698 29.309-1.5403 44.531-3.4235 6.2121-0.76857 6.0255-0.94651 9.2467 8.8193 2.8118 8.5247 3.5018 11.153 5.4987 20.948 2.7847 13.658 4.6901 27.257 3.8073 27.173-0.15602-0.0148-1.5797-0.88665-3.1637-1.9374z" fill="#dde5ed"/>
  <path d="m64.897 133.77c0.317-5.5405 3.4889-23.265 6.0244-33.665 0.53637-2.2 2.052-7.24 3.368-11.2 3.1822-9.5756 2.9762-9.3856 9.366-8.6402 4.7446 0.55344 16.739 1.8773 18.742 2.0686 2.6284 0.25103 17.505 1.0208 29.084 1.5049l9.5645 0.39988-0.23374 13.933c-0.12855 7.6634-0.25657 13.957-0.28449 13.986-0.0279 0.0289-2.0668 0.21766-4.5308 0.41937-24.705 2.0225-48.244 9.8742-67.771 22.606-1.7221 1.1228-3.2201 2.0415-3.3289 2.0415-0.10881 0-0.10889-1.5546-1.69e-4 -3.4548z" fill="#dde5ed"/>
  <path d="m329.61 136.67c5.2492-13.994 21.696-25.127 43.363-29.352 7.9544-1.551 11.876-1.8833 22.222-1.883 7.0663 2e-4 10.867 0.14718 14.4 0.55691 10.377 1.2034 18.77 3.3323 25.923 6.576 1.209 0.54821 2.2553 1.0537 2.3249 1.1234 0.0697 0.0697-2.2977 3.694-5.2609 8.0541-2.9631 4.3601-5.486 8.0729-5.6065 8.2506-0.133 0.1963-1.9543-0.51427-4.64-1.8102-7.2284-3.488-14.041-5.4734-21.608-6.2978-12.084-1.3163-24.457 1.3477-33.053 7.1163-2.6108 1.7521-6.1613 5.4534-7.52 7.8394l-1.12 1.9668-15.117 0.01-15.117 9e-3 0.81021-2.16z" fill="#6ba539"/>
  <path d="m0.16257 140.35c0.01337-6.97 1.8919-18.184 5.2669-31.44 2.9144-11.447 4.7245-16.15 11.127-28.911 5.1566-10.277 5.3932-10.609 7.5667-10.609 0.52659 0 3.7377 0.58112 7.1359 1.2914 10.938 2.2863 36.246 7.6902 36.635 7.8225 0.257 0.0875 0.02454 1.025-0.73079 2.9472-5.6084 14.273-9.5917 32.005-11.23 49.992-0.30596 3.3589-0.67806 6.1071-0.82687 6.1071s-12.492 1.224-27.429 2.72-27.24 2.72-27.339 2.72c-0.0994 0-0.17846-1.188-0.17567-2.64z" fill="#41b6e6"/>
  <path d="m327.84 144.77c0-2.6456-1.3675-2.4262 15.118-2.4262h14.729l-0.20349 1.4847c-0.11191 0.81657-0.20349 1.6806-0.20349 1.92 0 0.36081-2.5201 0.43534-14.72 0.43534h-14.72z" fill="#6ba539"/>
  <path d="m703.44 145.81c-3.3902-4.8123-26.216-38.432-26.376-38.849-0.15114-0.39378 1.6125-0.45893 12.424-0.45893h12.6l7.754 11.185c4.2647 6.1518 7.754 11.271 7.754 11.375 0 0.17823-11.98 17.928-12.491 18.508-0.13005 0.14735-0.87936-0.64465-1.6651-1.76z" fill="#41b6e6"/>
  <path d="m328.35 152.67c-0.096-0.484-0.26745-1.42-0.38105-2.08l-0.20656-1.2h29.68l0.36778 1.52c0.20227 0.836 0.46778 1.772 0.58998 2.08 0.2097 0.52854-0.62313 0.56-14.827 0.56h-15.049z" fill="#6ba539"/>
  <path d="m1124.4 161.42-13.807-20.284v-34.632h27.84v37.6c0 20.68-0.051 37.6-0.113 37.6s-6.3261-9.1279-13.92-20.284z" fill="#ffb549"/>
  <g fill="#343579">
   <path d="m1039.8 146.66v-40.163l38.24 0.165 16.374 24c28.974 42.47 37.054 54.323 37.658 55.251l0.5914 0.90884h-30.385l-34.478-50.72-0.164 50.72h-27.836z"/>
   <path d="m822.24 154.83v-32h-39.04v-16.32h106.24v16.315l-38.88 0.165-0.0815 31.92-0.0816 31.92h-28.157z"/>
   <path d="m688.35 186.43c0.13088-0.22188 12.478-18.295 27.438-40.163l27.2-39.76h14.023c7.7125 0 14.023 0.11719 14.023 0.26043 0 0.14323-12.276 18.179-27.28 40.08l-27.28 39.82-14.181 0.0833c-11.338 0.0666-14.133 3e-3 -13.943-0.32z"/>
   <path d="m539.26 186.39c0.14176-0.24482 12.426-18.387 27.298-40.316l27.04-39.871 26.24 0.0216 54.586 80.443-14.493 0.0834c-7.9712 0.0459-14.641 0.0269-14.821-0.0422-0.18023-0.0691-8.9642-13.39-19.52-29.602-10.556-16.212-19.29-29.441-19.409-29.398-0.11894 0.0435-3.9621 5.8014-8.5403 12.795l-8.324 12.716 25.393 0.1674 9.3758 15.36-45.005 0.32-11.443 17.6-14.318 0.0833c-12.155 0.0707-14.279 0.0161-14.06-0.36182z"/>
   <path d="m453.92 146.67v-40.16h28.16v64.32h25.6c14.08 0 25.6 0.0787 25.6 0.17483 0 0.0962-2.3849 3.6962-5.2997 8l-5.2998 7.8252h-68.761z"/>
  </g>
  <path d="m138.72 186.96c0-0.10003 3.9686-5.4386 8.8191-11.864 4.8505-6.425 8.8896-11.865 8.9757-12.09 0.12236-0.31888-1.264-0.44973-6.3391-0.5983-3.5726-0.10458-11.68-0.62418-18.016-1.1547-6.336-0.53048-17.28-1.2609-24.32-1.6232-29.371-1.5115-69.458-4.2177-95.04-6.4159-4.048-0.34784-8.548-0.72571-10-0.83969l-2.64-0.20726v-2.3737l2.16-0.19783c26.415-2.4194 72.595-5.6276 104.4-7.253 6.952-0.35527 15.376-0.86481 18.72-1.1323 18.828-1.5062 22.261-1.7519 24.919-1.7835 1.6064-0.0191 3.7458-0.1271 4.7542-0.24l1.8335-0.20527-8.236-11.36c-4.5298-6.248-8.5766-11.839-8.9928-12.425l-0.7568-1.0646 17.48 0.4666 30.317 26.543 7.3278 1.0543c11.53 1.6589 23.522 4.2254 31.273 6.6929l2.64 0.8404v2.4934l-2.8 0.8608c-8.4139 2.5867-20.578 5.1697-31.6 6.7103-3.344 0.46741-6.2895 1.0158-6.5455 1.2187-0.25604 0.2029-6.709 6.1649-14.34 13.249l-13.874 12.88h-10.06c-5.533 0-10.06-0.0818-10.06-0.18188z" fill="#2774ae"/>
  <path d="m960.02 105.27c-5.0876 0.0749-10.244 0.47567-15.389 1.2188-18.701 2.7011-34.932 10.369-43.561 20.576-5.6887 6.7299-8.1029 13.45-7.7031 21.441 0.39236 7.8428 3.4564 14.121 10.117 20.727 10.826 10.737 29.164 17.635 50.074 18.836 6.6077 0.37954 8.4143 0.3838 14.438 0.0234 14.466-0.8655 26.288-3.7994 36.959-9.168 6.3587-3.199 10.332-5.962 14.408-10.021 4.7916-4.7719 7.8256-9.8057 9.3262-15.475 0.9928-3.7499 0.9845-9.8665-0.018-13.645-1.8126-6.8336-6.2349-13.283-12.648-18.441-13.179-10.6-33.958-16.397-56.004-16.072zm0.5293 16.311c17.905-0.21079 34.174 7.4322 38.33 19.258 2.8828 8.2025-0.14122 16.662-8.1016 22.66-6.0808 4.582-14.15 7.3724-23.902 8.2656-10.24 0.93787-20.922-0.84448-28.918-4.8242-15.793-7.8603-19.928-22.625-9.4394-33.705 4.8054-5.0766 10.99-8.3135 19.502-10.209 4.1771-0.9302 8.3974-1.3967 12.529-1.4453z" fill="#343579"/>
  <path d="m386.4 188.11c-26.168-1.729-47.267-12.313-55.284-27.733-0.74598-1.4349-1.3563-2.7669-1.3563-2.96 0-0.24997 4.4181-0.35114 15.334-0.35114h15.334l0.64781 1.0482c0.9368 1.5158 4.9919 5.3337 7.3654 6.9346 9.308 6.2783 24.539 8.549 38.8 5.7844 4.6037-0.89243 10.78-3.0632 15.639-5.4962 3.3321-1.6687 4.4028-2.0751 4.6822-1.7772 0.31603 0.33689 9.4452 13.712 10.139 14.854 0.19482 0.32084-0.65619 0.86702-3.36 2.1565-8.0154 3.8227-17.475 6.2308-28.261 7.1945-5.2672 0.4706-15.156 0.64399-19.68 0.34508z" fill="#6ba539"/>
  <path d="m207.84 215.28c-0.968-0.14906-12.488-1.0734-25.6-2.0542s-25.424-1.9577-27.36-2.171l-3.52-0.38779-0.1686-20.879 3.1243-0.21347c25.264-1.7261 50.044-9.9839 70.741-23.573l1.0566-0.69376-0.17516 1.5619c-0.0963 0.85905-0.32484 2.9299-0.50779 4.6019-1.2459 11.387-4.5911 24.397-10.223 39.756-1.5868 4.3277-2.4614 4.8089-7.3676 4.0534z" fill="#deedcb"/>
  <path d="m78.59 214.75c-1.0016-1.0039-2.1117-3.7562-5.0011-12.4-4.1496-12.414-6.5631-23.643-7.6175-35.442l-0.136-1.5219 1.1609 0.7167c0.6385 0.39418 2.6009 1.6241 4.3609 2.733 3.959 2.4946 15.749 8.4234 20.48 10.299 14.233 5.642 28.775 9.0355 44.084 10.287l4.7243 0.38633-0.1686 20.858-3.52 0.38803c-1.936 0.21341-14.248 1.188-27.36 2.1657-13.112 0.97771-24.776 1.9171-25.92 2.0874-3.3804 0.50344-4.1084 0.42368-5.0872-0.55736z" fill="#deedcb"/>
  <path d="m264.64 225.69c-1.056-0.23671-9.984-2.0976-19.84-4.1353s-18.316-3.863-18.8-4.0563c-1.1485-0.45857-1.1494-1.3509-4e-3 -4.3497l0.88432-2.316 25.84-4e-3c14.212-2e-3 25.84 0.0579 25.84 0.13343 0 1.0328-6.5334 12.934-7.9616 14.503-0.77455 0.85077-2.8227 0.92824-5.9584 0.22535z" fill="#cb333b"/>
  <path d="m23.123 225.46c-2.9877-2.4556-12.28-22.011-16.08-33.838-1.6109-5.0146-3.8039-13.656-4.7914-18.88-1.0217-5.405-1.7955-13.124-1.3142-13.11 1.8329 0.0519 54.936 5.68 55.095 5.8393 0.11711 0.11711 0.48201 2.341 0.81087 4.942 1.7795 14.074 5.1928 27.759 10.422 41.785 0.97116 2.6048 1.7032 4.7986 1.6267 4.8751-0.16116 0.16116-8.1146 1.8852-21.444 4.6482-5.1546 1.0685-11.952 2.5093-15.106 3.2017-6.6951 1.47-7.9932 1.5456-9.2199 0.53738z" fill="#41b6e6"/>
  <path d="m716.48 228.85c-5.8346-3.0759-4.3793-11.51 2.0974-12.155 1.4981-0.14936 1.9879-0.0445 3.4916 0.74766 1.4303 0.75351 1.9169 1.2401 2.6704 2.6704 0.7921 1.5036 0.897 1.9935 0.74765 3.4916-0.33529 3.363-3.0463 5.7916-6.4349 5.7646-0.88672-7e-3 -2.0442-0.24056-2.5722-0.51891z" fill="#ee2737"/>
  <g fill="#41b6e6">
   <path d="m1052.5 210.16-4.7129 0.18359-6.0625 14.02c-3.3341 7.7098-6.0625 14.08-6.0625 14.158 0 0.0782 1.1321 0.14258 2.5157 0.14258h2.5156l1.4043-3.3574 1.4043-3.3555 6.5957-4e-3 6.5957-2e-3 1.4043 3.3555 1.4043 3.3574 2.6445 4e-3 2.6445 2e-3 -0.4765-1.1387c-0.2619-0.62686-3.0267-7.042-6.1446-14.254l-5.6699-13.111zm-2.4492 6.6445c0.1647-0.02 0.6167 0.74151 1.0469 1.7754 0.4588 1.1028 1.4884 3.5514 2.2871 5.4434l1.4512 3.4414h-4.627c-2.5451 0-4.6269-0.10824-4.6269-0.24024 3e-4 -0.36781 4.1637-10.127 4.4394-10.406 0.01-8e-3 0.018-0.0123 0.029-0.0137z"/>
   <path d="m1005.3 224.59v-14.08h4.8v28.16h-4.8z"/>
   <path d="m904.8 210.46v28.209h4.8008v-9.5996h6.3516l3.3984 4.8008 3.3965 4.7988h2.8672c1.5767 0 2.8652-0.0847 2.8652-0.18946 0-0.10477-1.647-2.4708-3.6621-5.2559l-3.6641-5.0625 0.93945-0.32812c1.8508-0.64517 3.6809-2.1988 4.6289-3.9316 0.7968-1.4565 0.93641-2.0687 0.94141-4.1113 4e-3 -1.32-0.18924-2.9651-0.42969-3.6543-0.61312-1.7572-2.6271-3.8193-4.5996-4.709-1.5903-0.71724-2.0719-0.7585-9.7539-0.85938l-8.0801-0.10742zm4.8008 4.4727 4.8789 0.10742c4.2028 0.0936 5.0354 0.19919 6 0.75977 2.4642 1.432 2.9619 4.9637 0.98633 7.002-0.56349 0.58137-1.5724 1.2212-2.2422 1.4219-0.66979 0.20068-3.1083 0.36524-5.4199 0.36524h-4.2031v-9.6562z"/>
   <path d="m804 224.59v-14.08h20.8v4.48h-16v7.36h14.08v4.48h-14.08v7.36h16.32v4.48h-21.12z"/>
   <path d="m672.48 233.15v-5.52l-11.167-17.12h5.9506l3.8091 6.0791c2.095 3.3435 3.8811 6.0724 3.9691 6.0643 0.088-8e-3 1.8853-2.7437 3.994-6.079l3.8331-6.0643h2.8249c2.1702 0 2.7712 0.0927 2.5931 0.4-0.12752 0.22-2.656 4.1015-5.6189 8.6256l-5.3871 8.2256v10.909h-4.8v-5.52z"/>
   <path d="m625.04 226.91-0.0839-11.76-8.8-0.178v-4.462h22.72v4.48h-8.96v23.68h-4.7921z"/>
   <path d="m566.56 224.59v-14.08h4.6553l7.3524 9.5894 7.3523 9.5894 0.1694-19.179h4.7906v28.16h-4.1175l-15.242-19.833-0.0845 9.9164-0.0846 9.9164h-4.7909z"/>
  </g>
  <path d="m151.2 229.08c0-8.7767 0.0465-9.611 0.53609-9.611 0.90075 0 40.886 2.8848 41.156 2.9692 0.82781 0.2591-7.8144 5.079-14.592 8.1383-10.567 4.7696-17.798 7.0218-25.42 7.9172l-1.68 0.19734z" fill="#deedcb"/>
  <path d="m137.44 238.32c-7.2644-0.92108-16.825-4.2838-28.64-10.073-4.5846-2.2465-9.9947-5.4539-9.6987-5.7499 0.11621-0.11621 39.609-3.0321 41.067-3.0321 0.40539 0 0.47191 1.3531 0.47191 9.6 0 8.945-0.0382 9.5972-0.56 9.5594-0.308-0.0223-1.496-0.15925-2.64-0.3043z" fill="#deedcb"/>
  <g fill="#41b6e6">
   <path d="m524.12 238.53c-3.4256-0.83133-5.9095-3.004-7.2682-6.3576-0.62906-1.5526-0.68487-2.3771-0.78983-11.671l-0.11292-10h4.7892l0.14227 9.04c0.0916 5.8185 0.28074 9.4757 0.53085 10.263 1.3065 4.1112 6.4885 5.8675 10.482 3.5527 0.995-0.57672 1.567-1.2445 2.24-2.6149l0.90374-1.8404 0.10915-9.2 0.10919-9.2h4.7417v8.9442c0 10.805-0.29837 12.568-2.6699 15.776-2.2284 3.0141-8.2273 4.5166-13.207 3.308z"/>
   <path d="m963.37 238.98c-3.7158-0.78385-7.7848-3.9801-9.3593-7.3517-2.7517-5.8927-1.7857-12.634 2.4692-17.229 3.5033-3.784 8.5594-5.2751 14.272-4.209 1.8235 0.34031 5.059 1.8135 6.3128 2.8743l0.74912 0.6338-1.539 1.8451c-0.84643 1.0148-1.57 1.8956-1.6079 1.9572-0.0379 0.0617-0.6185-0.32862-1.2901-0.8673-2.4235-1.9437-5.9785-2.6651-8.9375-1.8136-1.532 0.44085-2.2189 0.88481-3.6788 2.3774-2.2596 2.3103-3.0074 4.2722-2.9729 7.7991 0.0214 2.1731 0.16246 2.7582 1.1161 4.6258 2.4622 4.822 8.0215 6.5426 13.036 4.0346l1.8158-0.90815 0.0924-2.8 0.0925-2.8h-7.0649v-4.16h11.84v12.174l-1.9287 1.2466c-2.8101 1.8162-5.3608 2.5989-8.9513 2.7468-1.672 0.0688-3.6815-0.0102-4.4655-0.17557z"/>
   <path d="m863.99 209.88c-0.97871 0.0121-1.9786 0.11283-2.9902 0.30469-5.4084 1.0257-10.045 5.637-11.203 11.143-1.668 7.9301 2.7435 15.464 10.236 17.479 5.2667 1.4163 10.805-0.0287 14.531-3.791 3.7648-3.801 5.1893-9.1713 3.8242-14.412-1.7239-6.6186-7.5475-10.807-14.398-10.723zm0.0449 4.6074c4.3492-0.1047 8.0561 2.81 9.2871 7.6152 0.9352 3.6505-0.0445 7.1854-2.7344 9.8652-1.4579 1.4525-2.1466 1.8813-3.7285 2.3223-2.1194 0.59077-2.8834 0.62255-4.7051 0.19336-3.7731-0.88891-6.4384-3.7807-7.2969-7.916-1.0469-5.0431 2.0216-10.399 6.707-11.705 0.83785-0.23362 1.6653-0.35561 2.4707-0.375z"/>
   <path d="m762.41 238.98c-3.7158-0.78385-7.7848-3.9801-9.3593-7.3517-2.7517-5.8927-1.7857-12.634 2.4692-17.229 3.5033-3.784 8.5594-5.2751 14.272-4.209 1.8235 0.34031 5.059 1.8135 6.3128 2.8743l0.74912 0.6338-1.539 1.8451c-0.84643 1.0148-1.57 1.8956-1.6079 1.9572-0.0379 0.0617-0.6185-0.32862-1.2901-0.8673-2.4235-1.9437-5.9785-2.6651-8.9375-1.8136-1.532 0.44085-2.2189 0.88481-3.6788 2.3774-2.2596 2.3103-3.0074 4.2722-2.9729 7.7991 0.0214 2.1731 0.16246 2.7582 1.1161 4.6258 2.4622 4.822 8.0215 6.5426 13.036 4.0346l1.8158-0.90815 0.0924-2.8 0.0925-2.8h-7.0649v-4.16h11.84v12.174l-1.9287 1.2466c-2.8101 1.8162-5.3608 2.5989-8.9513 2.7468-1.672 0.0688-3.6815-0.0102-4.4655-0.17557z"/>
   <path d="m475.51 209.88c-0.97871 0.0121-1.9786 0.11283-2.9902 0.30469-5.4084 1.0257-10.045 5.637-11.203 11.143-1.668 7.9301 2.7435 15.464 10.236 17.479 5.2667 1.4163 10.805-0.0287 14.531-3.791 3.7648-3.801 5.1893-9.1713 3.8242-14.412-1.7239-6.6186-7.5475-10.807-14.398-10.723zm0.0469 4.6074c4.3492-0.1047 8.0541 2.81 9.2852 7.6152 0.9352 3.6505-0.0446 7.1854-2.7344 9.8652-1.4579 1.4525-2.1466 1.8813-3.7285 2.3223-2.1194 0.59077-2.8834 0.62255-4.7051 0.19336-3.7731-0.88891-6.4365-3.7807-7.2949-7.916-1.0469-5.0431 2.0216-10.399 6.707-11.705 0.83785-0.23362 1.6653-0.35561 2.4707-0.375z"/>
   <path d="m422.88 238.87c-6.2252-1.6947-10.56-7.4368-10.56-13.988 0-7.4912 4.5731-13.355 11.474-14.713 2.733-0.53768 6.5936-0.20789 9.0148 0.77009 2.0153 0.81401 4.7646 2.6917 4.7813 3.2654 5e-3 0.18648-0.67411 1.1005-1.5101 2.0312l-1.52 1.6922-1.44-1.157c-1.9128-1.5368-5.2398-2.5743-7.2914-2.2736-2.3043 0.33767-3.923 1.1953-5.5417 2.9362-3.5656 3.8347-3.6097 10.251-0.0973 14.157 1.9224 2.1382 3.8484 3.0383 6.5213 3.0478 2.6388 9e-3 3.9988-0.46403 6.3891-2.2236l1.9054-1.4027 2.918 3.3332-1.4962 1.3813c-0.82291 0.75971-2.0433 1.6604-2.712 2.0015-2.9236 1.4915-7.7016 1.9951-10.835 1.142z"/>
  </g>
  <path d="m181.87 280.67c5.8074-5.94 11.764-12.186 13.237-13.879 8.0615-9.2676 15.752-20.648 21.74-32.17 1.2665-2.4371 2.5893-4.8452 2.9396-5.3514 0.94551-1.3666 3.1351-2.2326 4.9718-1.9665 2.1879 0.31704 38.826 7.8021 38.989 7.9654 0.21883 0.21883-1.1274 2.6993-2.8596 5.2691-14.078 20.885-57.349 46.58-85.206 50.597-5.1021 0.73572-5.6223 1.6154 6.1876-10.464z" fill="#ee2737"/>
  <path d="m115.84 290.8c-26.992-4.7427-66.806-28.275-81.841-48.372-1.9672-2.6296-4.5619-6.9018-4.3317-7.132 0.14616-0.14617 26.646-5.5814 35.82-7.347 6.6575-1.2812 7.1144-1.0448 10.515 5.4413 4.6119 8.7965 9.8836 17.311 14.868 24.015 5.7542 7.7388 9.1026 11.408 27.509 30.145l3.8508 3.92-1.4352-0.0259c-0.78936-0.0142-3.0192-0.30415-4.9552-0.64432z" fill="#2774ae"/>
  <path d="m135.36 288.89c-15.906-12.735-28.703-26.269-39.386-41.651-5.5695-8.0201-13.812-22.336-12.859-22.336 0.24652 0 1.7345 1.0335 3.3066 2.2967 9.062 7.2813 20.513 13.08 32.385 16.398 6.0804 1.6996 16.083 3.3851 20.089 3.3851h1.7436v22.88c0 12.584-0.108 22.878-0.24 22.875s-2.4-1.7341-5.04-3.8478z" fill="#702f8a"/>
  <path d="m151.52 270.26v-23.175l2.4337-0.20572c7.7557-0.6556 18.141-3.7778 31.778-9.5537 7.0444-2.9836 10.552-4.7722 17.517-8.9326 3.2061-1.915 6.0527-3.4819 6.3257-3.4819 0.3698 0 0.0762 0.79566-1.1511 3.12-11.75 22.252-26.846 40.93-46.024 56.942-3.113 2.5993-7.9835 6.4255-9.84 7.7302l-1.04 0.73091z" fill="#ffb549"/>
 </g>
</svg>

  );