export const ZoningPages = [
    {id : 0,
    cards : [0,1,2,3,4,5,6],
    name : ''},
    {id: 1,
    cards: [7,8,9,10,11,12],
    name: 'BoC Hearing Required'},
    {id: 2,
    cards: [13,14,15,16,17],
    name: 'Subdivision and Combination'},
    {id: 2,
    cards: [18,19],
    name: 'Trees'},
    {id: 2,
    cards: [20,21],
    name: 'Board of Zoning Appeals'}
];